import React, { useState } from "react";
import { Col, Row, Image } from "react-bootstrap";

import styles from "../Finances.module.css";

import dollar from "../images/dollar.svg";
import { payment } from "../scripts/confim.ts";
import { useStripe } from "@stripe/react-stripe-js";

export default function BalanceAndAddFunds({ handleSuccess, user, amount, setAmount, paymentMethod, setShow }) {
    const quickMoneyValue = ["5", "10", "15", "20", "50", "100"];
    const [isNotValidAmount, setIsNotValidAmount] = useState(true)
    const [loading, setLoading] = useState(false)
    const stripe = useStripe()

    const onChangeInputValue = (value) => {
        const folatValue = parseInt(value)
        setIsNotValidAmount(true)
        if (folatValue <= 0 || isNaN(folatValue)) {
            setAmount("")
            setIsNotValidAmount(true)
        } else if (folatValue < 5) {
            setAmount(folatValue)
            setIsNotValidAmount(true)
        }
        else {
            setAmount(folatValue)
            setIsNotValidAmount(false)
        }
    }

    const handelPayment = async () => {
        if (paymentMethod) {
            setLoading(true)
            await payment(amount, user, stripe, paymentMethod)
            setLoading(false)
            handleSuccess()
        } else {
            setShow(true)
        }
    }

    return (
        <div className={styles.balanceContainer}>
            <Row>
                <Col className={styles.bigText}>
                    Balance
                </Col>
            </Row>
            <Row>
                <Col className={styles.smallText}>
                    Credit Remaining
                </Col>
            </Row>
            <Row>
                <Col className={styles.balanceValue}>
                    ${user.wallet}
                </Col>
            </Row>
            <Row>
                <Col className={styles.bigText}>
                    Add Funds
                </Col>
            </Row>
            <Row>
                <Col className={styles.smallText} style={{ paddingBottom: "2px" }}>
                    Write sum
                </Col>
            </Row>
            <Row>
                <Col style={{ position: "relative" }}>
                    <input
                        className={`
                            ${styles.inputMoney} 
                            ${styles.inputText}
                                        `}
                        onChange={(e) => onChangeInputValue(e.target.value)}
                        value={amount}
                        type="number"
                        placeholder="0"
                    />
                    <div className={`${styles.dollar} ${styles.inputText}`}>$</div>
                </Col>
            </Row>
            <Row>
                <Col style={{ display: "flex", gap: "5px", paddingTop: "8px", paddingBottom: "8px" }}>
                    {quickMoneyValue.map((value, index) => (
                        <span
                            key={index}
                            className={`${styles.quickMoneyContainer} ${value === amount && styles.quickMoneyTextActive}`}
                            onClick={_ => onChangeInputValue(value)}
                        >
                            <Image src={dollar} alt='dollar' style={{ paddingBottom: "3px" }} />
                            <div className={styles.quickMoneyText}>{value}</div>
                        </span>
                    ))}
                </Col>
            </Row>
            <Row>
                <Col className={styles.extraSmallText}>
                    Funds are debited from the primary card
                </Col>
            </Row>
            <Row>
                <Col style={{ paddingBottom: "24px" }}>
                    <button
                        className={`
                                            ${styles.button} 
                                            ${styles.payButton}
                                        `}
                        disabled={isNotValidAmount || loading}
                        onClick={handelPayment}
                    >
                        Pay
                    </button>
                </Col>
            </Row>
        </div>
    );
}