import React, { useCallback, useEffect, useRef, useState } from 'react';
import { getDemos } from '../../api/demos';
import { Row, Col, Spinner, Container } from 'react-bootstrap';
import { HeaderSpacer } from '../Header/HeaderSpacer';
import Search from '../menu/Search';
import { CenteredContent } from "../Projects/ProjectMain/ProjectMainPage";
import FastSpeed from '../menu/FastSpeed';
import Card from '../Card';

function DemoList() {
    const [demos, setDemos] = useState([]);
    const [filteredDemos, setFilteredDemos] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const observer = useRef();
    const loadingRef = useRef(false);
    const [searchText, setSearchText] = useState('');
    const [isFast, setIsFast] = useState(false);

    const statePriority = {
        'RUNNING': 1,
        'STARTING': 2,
        'STOPPING': 3,
        'IDLE': 4,
        'ERROR': 5
    };

    const sortProjects = (projects) => {
        return projects.sort((a, b) => statePriority[a.state] - statePriority[b.state]);
    };

    const fetchData = async (page) => {
        if (loadingRef.current) return;
        setLoading(true);
        loadingRef.current = true;

        try {
            const data = await getDemos(page);
            const sortedDemos = sortProjects([...demos, ...data.results]);
            setDemos(sortedDemos);
            setFilteredDemos(sortedDemos);
            setHasMore(Boolean(data.next));
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
            loadingRef.current = false;
        }
    };

    useEffect(() => {
        fetchData(page);
    }, [page]);

    useEffect(() => {
        const filterDemos = () => {
            let filtered = demos;

            if (searchText) {
                filtered = filtered.filter(demo =>
                    demo.name.toLowerCase().includes(searchText.toLowerCase())
                );
            }

            if (isFast) {
                filtered = filtered.filter(demo => demo.is_optimized);
            }

            setFilteredDemos(filtered);
        };

        filterDemos();
    }, [searchText, isFast, demos]);

    const loadMoreDemos = useCallback(() => {
        if (hasMore && !loadingRef.current) {
            setPage(prevPage => prevPage + 1);
        }
    }, [hasMore]);

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 1.0
        };

        observer.current = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting) {
                loadMoreDemos();
            }
        }, options);

        return () => {
            if (observer.current) observer.current.disconnect();
        };
    }, [loadMoreDemos]);

    const lastDemoRef = useCallback(node => {
        if (observer.current) observer.current.disconnect();
        if (node) observer.current.observe(node);
    }, []);

    if (error) {
        return <div className="error">{error}</div>;
    }

    const renderDemos = (demos, title, condition) => (
        demos.some(condition) && (
            <Row className='pt-4 pb-3'>
                <h4 style={{ fontSize: "24px", fontWeight: "bold" }}>{title}</h4>
                {demos.map((demo, index) => (
                    condition(demo) && (
                        <Col key={index} xs={12} sm={6} lg={6} xl={6}
                            ref={demos.length === index + 1 ? lastDemoRef : null}
                        >
                            <Card props={demo} />
                        </Col>
                    )
                ))}
            </Row>
        )
    );

    return (
        <Container fluid="lg" style={{ minHeight: "calc(100vh - 190px)", marginTop: "0px" }}>
            <HeaderSpacer />
            <Row>
                <Col xs={12} md={8} lg={10}>
                    <h2>Explore model</h2>
                </Col>
            </Row>
            <Row>
                <Col xxl={3} lg={3} md={3}>
                    <Row className='mt-4 pt-1 px-2'>
                        <Col xs={12} xxl={12}>
                            <Search function={setSearchText} />
                        </Col>
                        <Col xs={12} xxl={12}>
                            <FastSpeed state={isFast} change={() => setIsFast(!isFast)} />
                        </Col>
                        <Col xs={12} xxl={12} className='p-0 ms-0'>
                            <hr />
                        </Col>
                        <Col xs={12} xxl={12}>
                            {/* Menu */}
                        </Col>
                    </Row>
                </Col>
                <Col lg={9} md={9}>
                    {renderDemos(filteredDemos, "Active projects", demo => statePriority[demo.state] <= 3)}
                    {renderDemos(filteredDemos, "Not Active projects", demo => statePriority[demo.state] >= 4)}
                    {loading && (
                        <Row>
                            <Col>
                                <CenteredContent>
                                    <Spinner animation="border" role="status" variant="secondary">
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner>
                                </CenteredContent>
                            </Col>
                        </Row>
                    )}
                </Col>
            </Row>
            <HeaderSpacer />
        </Container>
    );
}

export default DemoList;
