import React, { useEffect, useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import '../../styles/tab-styles.css';
import { Text, Grid, Button, Spacer, Loading } from '@nextui-org/react';
import { HeaderSpacer } from "../Header/HeaderSpacer";
import TransactionsTable from './TransactionsTable';
import PaymentsTable from './PaymentsTable';
import HardwareUsageTable from "./HardwareUsageTable";
import AddFundsModal from "./Modals/AddFundsModal";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { getUser } from "../../api/users";
import { getPayments, getTransactions, getSavedCards } from "../../api/finances";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { updateRequestUser } from "../../store/actions/user";
import { toastError } from "../../utils/toasts";

//react 
import { Container, Row, Col } from 'react-bootstrap';
//style
import styles from "./Finances.module.css";

import Vector912 from "../Projects/ProjectMain/images/Vector912.svg";
import PaymentsTableResult from './PaymentsTableResult/index.tsx';
import AddPayment from './Modals/AddPayment/index.tsx';
import AutoPayment from './AutoPayment/index.tsx';
import ViewCards from './ViewCards/index.jsx';
import BalanceAndAddFunds from './BalanceAndAddFunds/index.tsx';
import UsageTableResult from './UsageTableResult/index.tsx';
import TransactionsTableResult from './TransactionsTableResult/index.tsx';

function FinancesList() {
    const dispatch = useDispatch();
    const location = useLocation();
    const [isAddFundsModalOpen, setIsAddFundsModalOpen] = useState(false);
    const [transactions, setTransactions] = useState([]);
    const [payments, setPayments] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [amount, setAmount] = useState()
    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
    const [show, setShow] = useState(false);
    const [user, setUser] = useState(null);
    const requestUser = useSelector((state) => state.user.user);
    const activeUser = useSelector((state) => state.user.activeUser);
    const [isAutoPayment, setIsAutoPayment] = useState(false)

    // cards
    const [savedCards, setSavedCards] = useState([]);
    const [unsavedCards, setUnsavedCards] = useState([])
    const [allCards, setAllCards] = useState([])
    const [selectedCard, setSelectedCard] = useState(null);

    useEffect(() => {
        const fetchSavedCards = async () => {
            try {
                const cards = await getSavedCards(user.username);
                setSavedCards(cards);
                const defaultCard = cards.find(card => card.is_default);
                if (defaultCard) {
                    setSelectedCard(defaultCard.stripe_payment_method_id);
                } else if (cards.length > 0) {
                    setSelectedCard(cards[0].stripe_payment_method_id);
                }
            } catch (error) {
                toastError("Failed to fetch saved cards");
            }
        };
        if (user) {
            fetchSavedCards();
        }
    }, [user]);

    useEffect(() => {
        setAllCards([...savedCards, ...unsavedCards])
    }, [savedCards, unsavedCards])

    const getInitialActiveTabIndex = () => {
        const queryParams = new URLSearchParams(location.search);
        const tab = queryParams.get('tab');
        switch (tab) {
            case 'hardware': return 0;
            case 'transactions': return 1;
            case 'payments': return 2;
            default: return 0;
        }
    };

    const [activeTabIndex, setActiveTabIndex] = useState(getInitialActiveTabIndex);
    useEffect(() => {
        setActiveTabIndex(getInitialActiveTabIndex());
    }, [location.search]);

    const fetchTransactionsAndPayments = async () => {
        setIsLoading(true);
        try {
            const [updatedRequestUser, updatedActiveUser] = await Promise.all([
                getUser(requestUser?.username),
                getUser(activeUser?.username),
            ]);
            setUser(updatedActiveUser);
            setIsAutoPayment(updatedActiveUser.auto_payment_enabled)

            dispatch(updateRequestUser(updatedRequestUser));

            if (updatedActiveUser) {
                const [transactionsData, paymentsData] = await Promise.all([
                    getTransactions(updatedActiveUser.username),
                    getPayments(updatedActiveUser.username)
                ]);
                setTransactions(transactionsData);
                setPayments(paymentsData);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchTransactionsAndPayments();
    }, [dispatch, requestUser?.username, activeUser?.username]);

    const openAddFundsModal = () => {
        setIsAddFundsModalOpen(true);
    };

    const closeAddFundsModal = () => {
        setIsAddFundsModalOpen(false);
    };

    const handleSuccess = async () => {
        const updatedUser = await getUser(user.username);
        setUser(updatedUser);
    };

    if (isLoading) {
        return (
            <div style={{ "width": "100%", backgroundColor: "rgba(238,238,238,0.8)", minHeight: "calc(100vh - 170px)", marginTop: "0px" }}>
                <Container css={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <Loading size="md">Loading finances data...</Loading>
                </Container>
            </div>
        );
    }

    if (!user) {
        return (
            <Container lg={true}>
                <HeaderSpacer />
                <Text h2>User data not available</Text>
            </Container>
        );
    }

    return (
        <div style={{ "width": "100%", backgroundColor: "rgba(238,238,238,0.8)" }}>
            <Container fluid="lg" style={{ minHeight: "calc(100vh - 190px)", marginTop: "0px" }}>
                <Row>
                    <HeaderSpacer />
                    <Col xs={12} md={8} lg={10}>
                        <h2>Payments</h2>
                    </Col>
                    <Col>
                    </Col>
                    <HeaderSpacer />
                </Row>
                <Elements stripe={stripePromise}>
                    <Row style={{ padding: "0 12px" }}>
                        <Col xs={12} md={6 } className={styles.payment}>
                            <BalanceAndAddFunds 
                                handleSuccess={handleSuccess}
                                user={user} 
                                amount={amount} 
                                setAmount={setAmount} 
                                paymentMethod={selectedCard}
                                setUnsavedCards={setUnsavedCards} 
                                setShow={setShow}
                            />
                            <AutoPayment 
                                isAutoPayment={isAutoPayment} 
                                user={user}
                                savedCards={allCards}
                                handleSuccess={handleSuccess}
                                selectedCard={selectedCard}
                                setSelectedCard={setSelectedCard}
                        />
                        </Col>
                        {/* payment */}
                        <Col className={styles.payment}>
                            <Row>
                                <Col className={styles.bigText}>
                                    Payment Methods
                                </Col>
                            </Row>
                            <ViewCards savedCards={allCards} selectedCard={selectedCard} setSelectedCard={setSelectedCard} />
                            <Row>
                                <Col>
                                    <button
                                        className={`${styles.btnAddPayment} ${styles.smallText}`}
                                        onClick={_ => setShow(!show)}
                                    >
                                        Add payment method </button>
                                    <AddPayment 
                                        amount={amount}
                                        user={user}
                                        show={show} setShow={setShow} setUnsavedCards={setUnsavedCards} />
                                </Col>
                            </Row>
                            {/*<Row>*/}
                            {/*    <Col style={{ paddingBottom: "14px", paddingTop: "6px" }}>*/}
                            {/*        <Image src={Vector912} alt="vector" style={{ width: "100%", paddingLeft: "5px" }} />*/}
                            {/*    </Col>*/}
                            {/*</Row>*/}
                            {/*<Row>*/}
                            {/*    <Col className={styles.smallText} style={{ paddingBottom: "4px" }}>*/}
                            {/*        Billing contact*/}
                            {/*    </Col>*/}
                            {/*</Row>*/}
                            {/*<Row>*/}
                            {/*    <Col>*/}
                            {/*        <input type="email" className={styles.inputMoney} value="example@mail.net" />*/}
                            {/*    </Col>*/}
                            {/*</Row>*/}
                            {/*<Row>*/}
                            {/*    <Col style={{ paddingTop: "24px" }}>*/}
                            {/*        <button className={styles.btn}>Save</button>*/}
                            {/*    </Col>*/}
                            {/*</Row>*/}
                        </Col>
                    </Row>
                </Elements>
                <HeaderSpacer />
                {/* Table */}
                <Row style={{paddingBottom: "40px"}}>
                    <Col>
                        <PaymentsTableResult user={user} />
                    </Col>
                </Row>
                <Row style={{paddingBottom: "40px"}}>
                    <Col>
                        <UsageTableResult user={user} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <TransactionsTableResult user={user} />
                    </Col>
                </Row>

                <HeaderSpacer />
                {/*
                    <Grid.Container alignItems="center" justify="space-between">
                    <Grid xs={12} sm={6}>
                        <Text h2>Finances: ${user.wallet}</Text>
                    </Grid>
                    <Grid xs={12} sm={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button onClick={openAddFundsModal} color="gradient" auto>
                            <Text style={{ color: 'white' }}>Add funds</Text>
                        </Button>
                    </Grid>
                </Grid.Container>
                <Spacer y={1} />
                <Tabs selectedIndex={activeTabIndex} onSelect={index => setActiveTabIndex(index)} style={{ borderBottom: 'none' }}>
                    <TabList style={{
                        borderBottom: "none",
                        padding: "0",
                        margin: "0",
                        marginBottom: "5px",
                        paddingBottom: "15px",
                        overflowY: "hidden",
                        overflowX: "auto",
                    }}>
                        <Tab>Hardware Usage</Tab>
                        <Tab>Transactions</Tab>
                        <Tab>Payments</Tab>
                    </TabList>
                    <TabPanel style={{ minHeight: "calc(100vh - 290px)", marginTop: "0px" }}>
                        <HardwareUsageTable username={user.username} />
                    </TabPanel>
                    <TabPanel>
                        <TransactionsTable username={user.username} transactions={transactions} />
                    </TabPanel>
                    <TabPanel>
                        <PaymentsTable username={user.username} payments={payments} />
                    </TabPanel>
                </Tabs>
                <Elements stripe={stripePromise}>
                    <AddFundsModal
                        isOpen={isAddFundsModalOpen}
                        onClose={closeAddFundsModal}
                        user={user}
                        setUser={setUser}
                    />
                </Elements> 
                */}
                
            </Container>
        </div>
    );
}

export default FinancesList;