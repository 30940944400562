import { Input } from '@nextui-org/react';
import React, { Component } from 'react';

export class InputInt extends Component {
    constructor(props) {
        super(props);
        this.inputRef = React.createRef();
    }

    componentDidMount() {
        this.inputRef.current.addEventListener('wheel', e => {
            e.preventDefault();
            e.stopPropagation();
        }, { passive: false });
    }

    render() {
        const { 
            formData,
            formErrors, 
            handleChange, 
            name, 
            isLoading, 
            isInstantGeneratingEnabled, 
            type, 
            detail 
        } = this.props;

        return (
            <Input
                ref={this.inputRef}
                disabled={isLoading && !isInstantGeneratingEnabled}
                borderWeight={"normal"}
                bordered={true}
                fullWidth={true}
                style={{ width: '100%', borderWidth: "2px" }}
                type="number"
                value={formData[name] ? formData[name] : ''}
                helperText={formErrors[name] || ''}
                status={formErrors[name] ? 'error' : 'default'}
                onChange={(e) => handleChange(name, e.target.value, type, detail)}
            />
        );
    }
}