import { Row, Col, Form } from "react-bootstrap";
import styles from "./style.module.css";
import Lightning from "./media/lightning";

export default function FastSpeed({state, change}) {
    return (
        <Row className="mt-4">
            <Col xs={1} className="p-0">
                <Lightning />
            </Col>
            <Col  xs={6} className={`${styles.text} d-flex justify-content-start p-0`}>
                FlyMyAI Speed
            </Col>
            <Col className="d-flex justify-content-end p-0">
                <Form>
                    <Form.Check
                        checked={state}
                        type="switch"
                        onClick={change}
                    />
                </Form>
            </Col>
        </Row>
    )
}
