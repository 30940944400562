import React, { useEffect, useState, useRef, useCallback } from 'react';
import { getProjects } from '../../api/projects';
import { Loading } from '@nextui-org/react';
import { HeaderSpacer } from "../Header/HeaderSpacer";
import { useSelector } from "react-redux";
import { CenteredContent } from "./ProjectMain/ProjectMainPage";
import Card from '../Card';
import { Row, Col, Container } from 'react-bootstrap';

function ProjectList() {
    const [projects, setProjects] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const observer = useRef();
    const loadingRef = useRef(false);
    const username = useSelector((state) => state.user.activeUser.username);

    const statePriority = {
        'RUNNING': 1,
        'STARTING': 2,
        'STOPPING': 3,
        'IDLE': 4,
        'ERROR': 5
    };

    const fetchData = async (page, username) => {
        if (loadingRef.current) return;

        setLoading(true);
        loadingRef.current = true;

        try {
            const data = await getProjects(username, page);
            setProjects(prev => [...prev, ...data.results]);
            setHasMore(Boolean(data.next));
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
            loadingRef.current = false;
        }
    };

    useEffect(() => {
        setPage(1);
        setProjects([]);
        setHasMore(true);
        fetchData(1, username);
    }, [username]);

    useEffect(() => {
        if (page !== 1) {
            fetchData(page, username);
        }
    }, [page, username]);

    const loadMoreProjects = useCallback(() => {
        if (hasMore && !loadingRef.current) {
            setPage(prevPage => prevPage + 1);
        }
    }, [hasMore]);

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 1.0
        };

        observer.current = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting) {
                loadMoreProjects();
            }
        }, options);

        return () => {
            if (observer.current) observer.current.disconnect();
        };
    }, [loadMoreProjects]);

    const lastProjectRef = useCallback(node => {
        if (observer.current) observer.current.disconnect();
        if (node) observer.current.observe(node);
    }, []);

    if (error) {
        return <div className="error">{error}</div>;
    }

    const renderProjects = (projects, title, condition) => (
        projects.some(condition) && (
            <Row style={{ paddingBottom: "48px" }}>
                <h4 style={{ fontSize: "24px", fontWeight: "bold" }}>{title}</h4>
                {projects.map((project, index) => (
                    condition(project) && (
                        <Col key={index} xs={12} sm={6} md={6} lg={4} xl={4}
                            ref={projects.length === index + 1 ? lastProjectRef : null}>
                            <Card props={project} />
                        </Col>
                    )
                ))}
            </Row>
        )
    );

    return (
        <Container fluid="lg" style={{ minHeight: "calc(100vh - 190px)", marginTop: "0px" }}>
            <HeaderSpacer />
            <Row>
                <Col xs={3} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {/* <Button bordered color={'secondary'} auto onClick={() => setIsModalOpen(true)}>Create new project</Button> */}
                </Col>
            </Row>
            <HeaderSpacer />
            {renderProjects(projects, "Active projects", project => statePriority[project.state] <= 3)}
            {renderProjects(projects, "Not Active projects", project => statePriority[project.state] >= 4)}
            {loading && <CenteredContent><Loading color={"secondary"} /></CenteredContent>}
        </Container>
    );
}

export default ProjectList;
