import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import AutoPaymentModal from "./AutoPaymentModal.tsx";

import styles from "./index.module.css";

export default function AutoPayment({ handleSuccess, user, savedCards, selectedCard, setSelectedCard }) {
    const [showAutoPayment, setShowAutoPayment] = useState(false)
    return (
        <Row>
            <Col>
                <Row>
                    <Col
                        className={styles.bigText}
                        style={{ paddingBottom: "0px" }}
                    >
                        Auto Payment
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Row style={{ padding: "24px 0px" }}>
                            <Col style={{ display: "inline-flex", justifyContent: "start", gap: "16px" }}>
                                <div className={styles.valueContainer}>
                                    <div className={styles.valueContainerSmallText}>
                                        Payment
                                    </div>
                                    <div className={styles.valueContainerBigText}>
                                        $ {user.auto_payment_amount || 0}
                                    </div>
                                </div>
                                <div className={styles.valueContainer}>
                                    <div className={styles.valueContainerSmallText}>
                                        Minimum balance
                                    </div>
                                    <div className={styles.valueContainerBigText}>
                                        $ {user.auto_payment_threshold || 0}
                                    </div>
                                </div>
                                <div className={styles.valueContainer}>
                                    <div className={styles.valueContainerSmallText}>
                                        Status
                                    </div>
                                    <div className={styles.valueContainerBigText} >
                                        {user.auto_payment_enabled ? "Enabled" : "Disabled"}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <button
                                    className={`
                                            ${styles.button} 
                                            ${styles.payButton}
                                        `}
                                    onClick={_ => setShowAutoPayment(true)}
                                >Set Up</button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
            <AutoPaymentModal
                show={showAutoPayment}
                setShow={setShowAutoPayment}
                user={user}
                savedCards={savedCards}
                handleSuccess={handleSuccess}
                selectedCard={selectedCard}
                setSelectedCard={setSelectedCard}
            />
        </Row>
    );
}