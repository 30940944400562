import React from 'react';
import { Table, useAsyncList } from "@nextui-org/react";
import {getProjectUsage} from "../../api/finances";
import {getStateColor} from "../Flow/styles/styles";

function ProjectUsageTable({username}) {
    const columns = [
        { name: "Project", uid: "project" },
        { name: "State", uid: "state_display" },
        { name: "Created At", uid: "created_at" },
        { name: "Finished At", uid: "finished_at" },
        { name: "Accelerators", uid: "accelerators_display" },
        { name: "Hourly Price", uid: "hourly_price" },
        { name: "Duration (hours)", uid: "duration" },
        { name: "Cost ($)", uid: "cost" },
    ];

    async function load({ cursor }) {
        const page = cursor || 1;
        const response = await getProjectUsage(username, page);
        return {
            items: response.results,
            cursor: response.next ? response.next.split('=')[1] : null,
        };
    }

    const list = useAsyncList({ load });

    return (
        <div style={{height: '30vh'}}>
        <Table
            aria-label="Project Usage"
            css={{ minWidth: "100%", overflowY: "auto", height: "65vh" }}
            shadow={false}
            bordered
        >
            <Table.Header columns={columns}>
                {(column) => (
                    <Table.Column key={column.uid}>{column.name}</Table.Column>
                )}
            </Table.Header>
            <Table.Body
                items={list.items}
                loadingState={list.loadingState}
                onLoadMore={list.loadMore}
            >
                {(item) => (
                    <Table.Row key={item.id}>
                        {(key) =>
                            <Table.Cell>
                                {key === 'created_at' || key === 'finished_at' ?
                                    item[key] ? new Date(item[key]).toLocaleString() : null
                                    : key === 'hourly_price' || key === 'cost' ?
                                        '$' + item[key]
                                        : key === 'state_display' ?
                                            <span style={{
                                                color: item.is_active ? getStateColor(item[key]) : 'black',
                                                fontWeight: item.is_active ? 'bold' : 'normal'
                                            }}>
                                                {item[key].toUpperCase()}
                                            </span>
                                            :
                                            item[key]
                                }
                            </Table.Cell>
                        }
                    </Table.Row>
                )}
            </Table.Body>
        </Table>
        </div>
    );
}

export default ProjectUsageTable;

