import axiosInstance from "./request";
const demosUrl = "demo/";

export async function getDemos(page=1, pageSize=12) {
    return await axiosInstance.get(demosUrl, {
        params: {
            page: page,
            page_size: pageSize,
            state: "RUNNING,STARTING,STOPING,IDLE,ERROR"
        },
    });
}

export async function cloneDemoProject(id) {
    return await axiosInstance.post(`projects/${id}/demo/clone`);
}
